export function convert(ip: string, 
                        name: string, 
                        type: string, 
                        shouldCalculateSize: boolean, 
                        col_sort: number, 
                        row_sort: number): string
{
    console.log(row_sort);
    console.log(col_sort);
    
    let input_text = ip;//$('#ip').val();
    let variable_name = name;//$('#varname').val();
    let variable_type = type;// $('#vartype').val();
    let calcsize = shouldCalculateSize;//$('#calcsize').prop('checked');
    let input_array = input_text.split('\n');
    //console.log(input_array);

    let l = input_array.length;
    let octal = 0;
    let elements = 0;
    let row_elements = 0;
    let textout = "";
    let row_size = 0;
    let row_size_set = false;
    for (let i = 0; i < l; i++){
        let _text = input_array[i];
        _text = _text.trim();
        let _text_row = _text.split('\t');
        let row_content = "";
        let row_break = false;
        if (_text_row.length > 0){
            let j = 0;
            let jl = _text_row.length;
            let jlm = false;
            let row_octal = 0;
            if (jl > 1){
                row_content += "{"
                jlm = true;
                if (jl != row_size){
                    if (!row_size_set){
                        row_size = jl;
                        row_size_set = true;
                    }
                    else{
                        return 'Invalid array, inconsistent row size';
                    }
                }
                
                //if (jl > row_size) row_size = jl;
            }
            while( j < _text_row.length){
                //console.log(_text_row);
                row_content += _text_row[j].trim();
                row_content += ", ";
                j++;
                row_octal++;
                if (row_octal == row_sort){
                    row_content += "\r\n  ";
                    row_break = true;
                    row_octal = 0;
                }
            }
            row_content = row_content.substr(0, row_content.length - 2);
            if (jlm) row_content += "}"
            console.log(row_content);
        }
        if (_text.length > 0){
            if (row_break) textout += "\r\n ";
            textout += row_content;
            elements++;
            textout += ", "; 
            
            if (i == l - 1) break;
            octal++;
            if (octal == col_sort){
                console.log('s');
                textout += "\r\n ";
                octal = 0;
            }
        }   
    }
    textout = textout.substr(0, textout.length - 2);
    textout += "};"
    //console.log(row_size);
    let header = variable_type + " " + variable_name;
    let bypass = false;
    if (row_size > 1){
        header += '[';
        if (calcsize) header += row_size;
        header += ']';
        if (elements == 1) bypass = true;
    }
    if (!bypass){
    header += "[";
    if (calcsize) header+= elements;
    header += "] = ";
    if (elements > 8) header += '\n';
    header += "{";
    }
    else{
        textout = textout.substr(0, textout.length - 2);
        textout += ";";
    }

    return header + textout;
    //$('#op').text(header + textout);
};