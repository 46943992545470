import { Site } from "./Site";

export abstract class Page
{
    constructor(protected site: Site, protected $: JQueryStatic)
    {

    }
    
    public abstract boot(): void;
}

export class EmptyPage extends Page 
{
    public boot()
    {

    }
}