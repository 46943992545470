import { Page } from './Page';

export class MainPage extends Page
{
    protected slideMaxHeight: number = 0;

    public boot()
    {
        this.addTargetBlankToExternalLinks();
        this.bindAboutLinkEvents();
        this.createLightslider();
    }

    protected addTargetBlankToExternalLinks()
    {
        this.$('a').each(
            (i, el) => {
            let regex = new RegExp('/' + window.location.host + '/');
            let href = (<HTMLAnchorElement>el).href ?? '';
                if (!regex.test(href)) {
                    this.$(el).attr("target","_blank");
                }
             }
        );
    }
    
    protected createLightslider()
    {
        let gallery = this.$('#image-gallery');
        gallery.lightSlider({
            gallery:false,
            pager: false,
            item:1,
            thumbItem:0,
            slideMargin: 0,
            speed:500,
            auto:true,
            loop:true,
            onSliderLoad: (ls) => 
            {
                this.calculateMaxSliderHeight(ls);
                this.updateSlideHeights(ls);
                ls.removeClass('cS-hidden');
                console.log('shown');
            }           
        });
    }

    protected calculateMaxSliderHeight(gallery: JQuery<HTMLElement>)
    {
        let maxHeight = gallery.height() ?? 0;
        let children = gallery.children('li');
        children.each(
            (i, el) => {
                let height = this.$(el).height() ?? 0;
                if (height > maxHeight) maxHeight = height;
            }
        );
        console.log("Gallery Max Height: ", maxHeight);
        this.slideMaxHeight = maxHeight;
    }

    protected updateSlideHeights(gallery: JQuery<HTMLElement>)
    {
        let children = gallery.children('li');
        children.each(
            (i, el) => {
                this.$(el).height(this.slideMaxHeight);
            }
        );
    }

    protected bindAboutLinkEvents()
    {
        $('#about_more_show').click((e) =>{
            console.log("About Click11");
            e.preventDefault();
            $('#about_more').show();
            $('#about_more_show').hide();
        });

        $('#about_more_hide').click((e) => {
          e.preventDefault();
          $('#about_more').hide();
          $('#about_more_show').show();
          var scrollTo = $('#about_content').offset()?.top ?? 0;
          console.log(scrollTo);
          let globalOffset = this.site.scrollOffset;
          $('html, body').scrollTop(scrollTo - globalOffset);
      });
    }
}