import $ from "jquery";
(<any>window).jQuery = $;
import 'bootstrap';
import 'lightslider';
import { Site } from "./Site";
import * as StrictValue from "./JQueryStrictValuePlugin";
StrictValue.loadPlugin($);
let page = (<any>window).pageName ?? 'index';
//console.log("Scrollspy? ", jQuery.fn.scrollspy);
let site = new Site(page, $);

$(document).ready(
    () => site.boot()
);