
import { Page } from "./Page";
import { convert as ArrayConvert } from "./CArrayConverter";

export class ArrayConverterPage extends Page
{

    public boot()
    {
        this.bindEvents();
    }

    protected convert()
    {
        let ip = this.getValueString('#ip');
        let name = this.getValueString('#vartype');
        let type = this.getValueString('#vartype');
        let colSort = this.getValueInt('#colsort');
        let rowSort = this.getValueInt('#rowsort');
        let calculateSize = this.$('#calcsize').prop('checked') ? true : false;
        var t = ArrayConvert(ip, name, type,calculateSize, colSort, rowSort); 
        this.$('#op').text(t);
    }

    protected getValueString(query: string)
    {
        return this.forceString(this.$(query).val() ?? '');
    }

    protected getValueInt(query: string)
    {  
        return this.forceInt(this.$(query).val() ?? '');
    }

    protected forceInt(value: string|number|string[]): number
    {
        if (typeof value != 'number')
        {
            if (Array.isArray(value)) value = value[0];
            let numeric = parseInt(value);
            if (isNaN(numeric)) return 0;
            else return numeric;
        }
        return value;
    }
    protected forceString(value: string|number|string[]): string
    {
        if (typeof value != 'string')
        {
            if (Array.isArray(value)) return value[0];
            return value.toString();
        }
        return value;
    }

    protected bindEvents()
    {
        let converter = () => this.convert();
        this.$('#convert').click(converter);
        this.$('#uTab').click(() =>
        {
            this.insertTab(this.$('#ip'));
            this.$('#ip').focus();
        })
        $('#colSort').change(converter);
        $('#rowSort').change(converter);
        $(".page-form").on('keydown', '#ip', (e) => 
        { 
            var keyCode = e.keyCode || e.which; 
            if (keyCode == 9) 
            { 
              e.preventDefault(); 
              this.insertTab($('#ip'));
            } 
        });
    }

    protected insertTab(ip: JQuery)
    {
        let x = ip.valString();
        x += '\t';
        ip.val(x);
    }



    }
