import { Page, EmptyPage } from "./Page";
import { MainPage } from "./MainPage";
import { SmoothIndicatedScroller } from "./SmoothIndicatedScroller";
import { ArrayConverterPage } from "./ArrayConverterPage";

export class Site 
{
    protected page: Page;
    protected scroller: SmoothIndicatedScroller;

    public constructor(page: string, protected $: JQueryStatic)
    {
        if (page == 'index')
        {
            this.page = new MainPage(this, $);
        }
        else if (page == 'array_converter')
        {
            this.page = new ArrayConverterPage(this, $);
        }
        else this.page = new EmptyPage(this, $);
        this.scroller = new SmoothIndicatedScroller($);
    }

    public get scrollOffset(): number
    {
        return this.scroller.scrollOffset;

    }
    public boot()
    {
        this.scroller.boot();
        this.page.boot();
        this.bindNavbarEvents();
        this.showLoadedModals();
    }

    protected bindNavbarEvents()
    {
        this.$('.nav-link').on('click', () => {
            $('#navbarSupportedContent').collapse('hide');
        });

        this.$('#navbarSupportedContent').on('show.bs.collapse', () => {
            $('#mainNav').addClass('nav-expanded');
        });

        this.$('#navbarSupportedContent').on('shown.bs.collapse', () => {
            this.scroller.updateScrollOffset();
        });

        this.$('#navbarSupportedContent').on('hidden.bs.collapse', () => {
            $('#mainNav').removeClass('nav-expanded');
            this.scroller.updateScrollOffset();
        });
    }

    protected showLoadedModals()
    {
        console.log("Loadeding modals");
        this.$('.modal.show-on-load').modal('show');
    }
}