
export interface JQueryStrictValue 
{
    valString(): string;
    valInt(): number;
    valFloat(): number;
}

export function loadPlugin($: JQueryStatic)
{
    $.fn.extend(
        {
            valString: 
                function(): string
                {
                    let el = this as JQuery;
                    let val = el.val();
                    if (typeof val == 'undefined') return '';
                    if (Array.isArray(val)) return val.join();
                    if (typeof val == 'string') return val;
                    else return val.toString();
                },
            valInt: 
                function(): number
                {
                    let el = this as JQuery;
                    let val = el.val();
                    if (typeof val == 'undefined') return NaN;
                    if (Array.isArray(val)) return NaN;
                    if (typeof val == 'string') return parseInt(val);
                    else return val;
                },
            valFloat: 
            function(): number
                {
                    let el = this as JQuery;
                    let val = el.val();
                    if (typeof val == 'undefined') return NaN;
                    if (Array.isArray(val)) return NaN;
                    if (typeof val == 'string') return parseFloat(val);
                    else return val;
                }
                
        }
    )
}