export class SmoothIndicatedScroller
{
    protected ignoreScrollspy: boolean = false;
    protected topScrollOffset = 0;
    protected resizeTimer: number = 0;

    constructor(protected $: JQueryStatic)
    {
      // console.log("JQuery", this.$);
      // console.log("Scrollspy?", this.$.fn.scrollspy);
    }

    public get scrollOffset(): number 
    {
        return this.topScrollOffset;
    }

    public boot()
    {
        let offsetVal = this.$( '#mainNav').height() ?? 0;
        console.log(offsetVal);
        let offset = offsetVal + 6;
        let adminVal = this.$( '#wpadminbar').height() ?? 0;
        this.$('body').scrollspy({
          target: '#mainNav',
          offset: offsetVal + adminVal + 20
        });
        this.bindEvents();
    }

    protected bindEvents()
    {
        let documentAnchorLinks = this.$( 'a[href*="#"]:not([href="#"])');
        documentAnchorLinks.click((e) => this.onAnchorClickSmoothScroll(e.currentTarget));
        this.$(window).on('activate.bs.scrollspy', () => this.onScrollSpy());
        this.$(window).resize(() => this.onResize());
        this.$(document).scroll(() => this.showScrollToTopIfScrolled());
        this.$('.js-scroll-trigger').click(() => {
          this.$('.navbar-collapse').collapse('hide');
        });
    }

    public updateScrollOffset()
    {
        let nav = this.$( '#mainNav');
        if (nav.hasClass('nav-expanded'))
        {
            //do nothing
        } 
        else 
        {
            let offsetVal = nav.height() ?? 0;
            let offset = offsetVal + 6;
            console.log("Scroll Offset:", offset);
            this.topScrollOffset = offset;
        }
    }

    public onScrollSpy()
    {
        console.log('Sspy', this);
        if (this.ignoreScrollspy) return;
        this.updateIndicator();
    }


    public onResize()
    {
        this.updateScrollOffset();
        clearTimeout(this.resizeTimer);
        this.resizeTimer = window.setTimeout(() =>{}, 100);
        this.updateIndicator();
    }

    protected animateIndicator(newPos: number, immediate?: boolean)
    {
        let navOffset = this.$('nav').offset() ?? {left: 0, top: 0};
        let navLinkTop = this.$('.nav-link').first().offset()?.top ?? 0;
        let navLinkHeight = this.$('.nav-link').first().height() ?? 0;
        //console.log('nav link height', navLinkHeight, navLinkTop);
        let indicator = this.$('.nav-indicator-master');
        let newOffset = {left: 0, top: 0};
        newOffset.top = navLinkTop + (navLinkHeight + 12);
        let indicatorWidth = indicator.outerWidth() ?? 0;
        newOffset.left = newPos - (indicatorWidth / 2) + 3;
        let newLeft = newOffset.left - navOffset.left;
        let newTop = newOffset.top - navOffset.top;
        if(immediate)
        {
          indicator.css({'display': 'block', 'left': newLeft, 'top': newTop});
        } 
        else 
        {
          indicator.animate({ left: newLeft, top: newTop}, 500, function(){
            indicator.css({'display': 'block'});
          });
        }
    }

    protected getElementsCenter(e: JQuery<HTMLElement>)
    {
        let eWidth = e.outerWidth() ?? 0;
        let eLeft = e.offset()?.left ?? 0;
        return eLeft + eWidth / 2;
    }

    protected setActiveLink(activeLink: HTMLElement)
    {
        let links = this.$('.nav-link');
        links.removeClass('active');
        console.log('ActiveLink:', activeLink);
        this.$(activeLink).addClass('active');
    }

    public updateIndicator(){
        let activeObj = this.$('.nav-link.active');
        console.log("Update Indicator: ", activeObj);
        if (activeObj.length > 0)
        {
            this.updateActiveIndidctor(activeObj.get(0));
        } 
        else 
        {
            this.$('.nav-indicator-master').hide();
        }
    }

    protected updateActiveIndidctor(activeLink: HTMLElement)
    {
        let activeObj = this.$(activeLink);
        console.log('Active:', activeObj);
        let top = activeObj.offset()?.top ?? 0;
        let width = activeObj.outerWidth() ?? 0;
        let destWidth = this.$('.nav-indicator-master').outerWidth() ?? 0;
        let left = activeObj.offset()?.left ?? 0;
        let off = {left: 0, top: 0};
        let centerPt = left + width / 2;
        let bodyOffset = this.$('nav').offset() ?? {left: 0, top: 0};
        off.top = top + 48;
        off.left = centerPt - destWidth/2 + 3;
        let newleft = off.left - bodyOffset.left;
        let newtop = off.top - bodyOffset.top + 5;
  
        console.log("top", top, "left", left, "width", width, "cp", centerPt);
        console.log("newtop", newtop, "newleft", newleft);

        let indicator = this.$('.nav-indicator-master');
        indicator.animate({ left: newleft, top: newtop}, 500, function(){
          console.log(indicator);
          console.log('show indicator');
          indicator.css({'display': 'block'});
        });
    }

    protected anchorPointsToInternalLink(link: HTMLAnchorElement): boolean
    {
      return (location.pathname.replace(/^\//, '') == link.pathname.replace(/^\//, '') 
        && location.hostname == link.hostname);
    }

    protected onAnchorClickSmoothScroll(el : HTMLElement)
    {
      if (el.tagName != 'A') return;
      let link = el as HTMLAnchorElement;
        if (this.anchorPointsToInternalLink(link)) {
            console.log('Link:', link);
            this.setActiveLink(link);
            let target = $(link.hash);
            console.log('Target:', target);
            target = target.length ? target : $('[name=' + link.hash.slice(1) + ']');
            if (target.length) {
                this.ignoreScrollspy = true;
                let offset = this.scrollOffset;
                let targetTop = target.offset()?.top;
                let scrollTop = targetTop ? targetTop - offset : offset;
                $('html, body').animate({
                  scrollTop: scrollTop
                }, 1000, "easeInOutExpo", () => {
                  this.ignoreScrollspy = false;
                });
                this.updateIndicator();
                // let center = this.getElementsCenter($(link));
                // this.animateIndicator(center);
                return false;
            }
        }
    }

    protected showScrollToTopIfScrolled()
    {
        let scrollDistance = $(document).scrollTop() ?? 0;
        if (scrollDistance > 100) 
        {
          $('.scroll-to-top').fadeIn();
        } 
        else 
        {
          $('.scroll-to-top').fadeOut();
        }
    }
}